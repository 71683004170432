<template>
  <div class="table-box">
    <el-table
      :data="tableData"
      @selection-change="changeSelection"
      :header-cell-style="{ background: '#f8f8f9', color: '#666' }"
    >
      <slot name="expand"></slot>
      <template v-for="(item, index) in tableColumn">
        <!-- 索引列 -->
        <el-table-column
          v-if="item.prop == 'index'"
          :key="'%' + index"
          type="index"
          :label="item.label"
          :width="item.width"
          :align="item.align"
        />
        <!-- 选择列 -->
        <el-table-column
          v-else-if="item.prop == 'selection'"
          :key="'%' + index"
          type="selection"
          :width="item.width"
          :align="item.align"
        />
        <!-- 此列需要自定义 -->
        <el-table-column
          v-else-if="item.slot"
          :key="'%' + index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
        >
          <template slot-scope="{ row }">
            <slot :name="item.slotName" :data="row" :row="row">{{
              item.slotName
            }}</slot>
            <!-- <slot name="sdfhfhf" :row="row"></slot> -->
          </template>
        </el-table-column>
        <!-- 正常列 -->
        <el-table-column
          v-else
          :key="'%' + index"
          :show-overflow-tooltip="item.showTooltip"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :sortable="item.sortable"
        >
          <template slot-scope="{ row }">
            <!-- 操作列 -->
            <template v-if="item.btnButton">
              <el-button
                size="small"
                v-for="(value, i) in item.btnButton"
                :key="'$' + i"
                :type="value.type"
                :icon="value.icon"
                @click="value.callback(row)"
                >{{ value.text }}
              </el-button>
            </template>
            <!-- 直接展示列 -->
            <template v-else>
              {{ row[item.prop] }}
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 分页 -->
    <div style="display: flex; justify-content: flex-end; align-item: center">
      <el-pagination
        style="margin-top: 10px; margin-right: 0"
        layout=" prev, pager, next, jumper"
        :total="total"
        @current-change="changeIndex"
        :current-page="currectIndex"
        :page-size="10"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ['currectIndex', 'pageTotal', 'tableData', 'tableColumn', 'total'],
  data() {
    return {};
  },
  methods: {
    // 改变选中行的回调
    changeSelection(val) {
      this.$emit('changeSelection', val);
    },
    // 改变页码
    changeIndex(val) {
      this.$emit('changeIndex', val);
    },
  },
};
</script>

<style></style>
